import styled from "styled-components";
import { Link } from "react-scroll";

export const Nav = styled.nav`
  height: 90px;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.125rem;
  position: fixed;
  top: 0;
  z-index: 10;
  backdrop-filter: ${({ scrollNav }) => (scrollNav ? "blur(19px)" : "none")};
  -webkit-backdrop-filter: ${({ scrollNav }) =>
    scrollNav ? "blur(19px)" : "none"};
  box-shadow: ${({ scrollNav }) =>
    scrollNav ? "0 4px 8px 0px rgba(0, 0, 0, .16)" : "none"};
  background-color: ${({ scrollNav }) =>
    scrollNav ? "rgba(26, 25, 25, 0.95)" : "none"};
  transition: 0.8s all ease;

  @media screen and (max-width: 960px) {
    margin-top: 0;
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 1100px;
`;

export const NavLogo = styled(Link)`
  color: #fff;
  justify-self: flex-start;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: bold;
  text-decoration: none;
  transition: 0.3s all ease;

  &:hover {
    transform: scale(1.1);
  }
`;

export const LogoWrap = styled.div`
  display: flex;
`;

export const NavLinkBtn = styled(Link)`
  color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
`;

export const NavBtn = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    margin-right: -20px;
  }
`;

export const NavBtnLinkStyled = styled.div`
  border-radius: 5px;
  background: #424971;
  white-space: nowrap;
  padding: 10px 22px;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 600;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    background-color: #4b5381;
    box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0.24);
  }
`;
