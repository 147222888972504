import styled from "styled-components";
import { Link } from "react-scroll";

export const FooterContainer = styled.footer`
  z-index: 3;
`;

export const FooterWrap = styled.div`
  padding: 48px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
`;

export const FooterContent = styled.section`
  max-width: 740px;
  width: 100%;
`;

export const FooterContentWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  margin: 40px 20px 0 auto;

  @media screen and (max-width: 820px) {
    flex-direction: column;
    margin: 0 auto;
  }
`;

export const FooterLinksContainer = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (max-width: 820px) {
    padding-top: 32px;
  }
`;

export const LogoWrap = styled(Link)`
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  transition: 0.3s all ease;

  &:hover {
    transform: scale(1.1);
  }

  @media screen and (max-width: 768px) {
    margin: 0 0 44px 0;
  }
`;

export const LogoWrapper = styled.div``;

export const WebsiteRights = styled.small`
  color: #fff;

  @media screen and (max-width: 768px) {
    margin: 0 0 20px 0;
  }
`;

export const SocialIcons = styled.div`
    display: flex;
    justify-content: space-between:
    align-items: center;
`;

export const SocialIconLink = styled.a`
  color: #fff;
  font-size: 40px;
  padding: 0 0 0 16px;
  transition: 0.3s all ease;

  &:hover {
    transform: scale(1.1);
  }

  @media screen and (max-width: 780px) {
    padding: 34px 8px 0 8px;
  }
`;
