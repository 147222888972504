import { useEffect } from "react";
import Script from "next/script";
import { Router, useRouter } from "next/router";
import { Nunito } from "next/font/google";
import { logEvent } from "firebase/analytics";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { motion } from "framer-motion";
import { analytics } from "@/config/firebase";
import Layout from "@/components/Layout";

const nunito = Nunito({
  subsets: ["latin"],
  variable: "--primary-font",
});

const GlobalStyle = createGlobalStyle`
  //Boilerplate
  html {
    height: 100%;
  }

  html,
  body {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
  }

  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: var(--primary-font);
    font-size: 16px;
    min-height: 100%;
    line-height: 1.5;
    background-color: #1A1919;
  }

  //Global
  .image {
    border-radius: 5px;
  }

  .toaster {
    margin-top: 100px;
  }
`;

const theme = {
  colors: {
    primary: "#424971",
  },
};

export default function App({ Component, pageProps }) {
  Router.events.on("routeChangeStart", () => NProgress.start());
  Router.events.on("routeChangeComplete", () => NProgress.done());
  Router.events.on("routeChangeError", () => NProgress.done());

  const router = useRouter();

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      const logFirebaseEvent = (url) => {
        logEvent(analytics, "screen_view", {
          firebase_screen: url,
          firebase_screen_class: url,
        });
      };

      router.events.on("routeChangeComplete", logFirebaseEvent);

      return () => {
        router.events.off("routeChangeComplete", logFirebaseEvent);
      };
    }
  }, [router.events]);

  return (
    <>
      <GlobalStyle />
      <Script
        id="microsoft-clarity"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(c,l,a,r,i,t,y){
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "${process.env.NEXT_PUBLIC_CLARITY_API_KEY}");
          `,
        }}
      />
      <ThemeProvider theme={theme}>
        <motion.div
          className={nunito.className}
          key={router.route}
          initial="pageInitial"
          animate="pageAnimate"
          variants={{
            pageInitial: {
              opacity: 0,
            },
            pageAnimate: {
              opacity: 1,
            },
          }}
        >
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </motion.div>
      </ThemeProvider>
    </>
  );
}
