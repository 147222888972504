import { useState, useEffect } from "react";
import Image from "next/image";
import { animateScroll as scroll } from "react-scroll";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  LogoWrap,
  NavLinkBtn,
  NavBtn,
  NavBtnLinkStyled,
} from "./NavbarElements";
import EyeLogo from "@/images/eye-logo.png";

const Navbar = () => {
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <Nav scrollNav={scrollNav} id="navigation">
        <NavbarContainer>
          <NavLogo to="navigation" onClick={toggleHome}>
            <LogoWrap>
              <Image src={EyeLogo} alt="logo" height={60} width={60} priority />
            </LogoWrap>
          </NavLogo>
          <NavBtn>
            <NavLinkBtn to="contact" smooth={true} duration={500} offset={-120}>
              <NavBtnLinkStyled>Contact</NavBtnLinkStyled>
            </NavLinkBtn>
          </NavBtn>
        </NavbarContainer>
      </Nav>
    </>
  );
};

export default Navbar;
