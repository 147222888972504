import Image from "next/image";
import { animateScroll as scroll } from "react-scroll";
import { FaTiktok } from "react-icons/fa";
import {
  FooterContainer,
  FooterWrap,
  FooterContent,
  FooterContentWrap,
  LogoWrap,
  LogoWrapper,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
} from "./FooterElements";
import EyeLogo from "@/images/eye-logo.png";

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <FooterContainer id="footer">
      <FooterWrap>
        <FooterContent>
          <FooterContentWrap>
            <LogoWrap to="navigation" onClick={toggleHome}>
              <LogoWrapper>
                <Image src={EyeLogo} alt="logo" height={60} width={60} />
              </LogoWrapper>
            </LogoWrap>
            <WebsiteRights>
              Alyssa Goldman © {new Date().getFullYear()}
            </WebsiteRights>
            <SocialIcons>
              <SocialIconLink
                href="https://www.tiktok.com/@blindcitytravel"
                target="_blank"
                rel="noopener"
                aria-label="Tik-Tok"
              >
                <FaTiktok />
              </SocialIconLink>
            </SocialIcons>
          </FooterContentWrap>
        </FooterContent>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
