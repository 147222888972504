import { Content } from "./LayoutElements";
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";

export default function Layout({ children }) {
  return (
    <>
      <Navbar />
      <Content id="content">{children}</Content>
      <Footer />
    </>
  );
}
